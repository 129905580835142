<template>
  <div>
    <transferenceManagebar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายละเอียดผู้ยืนย้าย (ประมวลผลแบบที่ 1)"
      >

        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
          class="mt-5"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>

        <v-radio-group v-model="position" @change="positionSearch()" row>
          <v-radio
            label="ผู้อำนวยการวิทยาลัย"
            value="ผู้อำนวยการวิทยาลัย"
          ></v-radio>
          <v-radio
            label="รองผู้อำนวยการวิทยาลัย"
            value="รองผู้อำนวยการวิทยาลัย"
          ></v-radio>
        </v-radio-group>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          filled
          class="mb-2"
        />
        <v-data-table
          :headers="showHeaders"
          :items="transference_manage_locations"
          :search="search"
          :loading="loading"
          disable-pagination
        >
          <template v-slot:item.person_has="{ item }">
            <v-chip v-if="item.person_has == null" color="green" dark>
              <v-icon>mdi-checkbox-multiple-marked-circle</v-icon>
            </v-chip>
            <v-chip v-else color="red" dark>
              <span style="font-size:16px;">{{ item.person_has }}</span>
            </v-chip>
          </template>

          <template v-slot:item.manage_case_move="{ item }">
            <div>
              <v-chip v-if="item.manage_case_move === 'normal'" color="primary"
                >ปกติ</v-chip
              >
              <v-chip v-if="item.manage_case_move === 'quality'" color="info"
                >คุณภาพ</v-chip
              >
              <v-chip v-if="item.manage_case_move === 'special'" color="warning"
                >กรณีพิเศษ</v-chip
              >
            </div>
          </template>




          <template v-slot:item.person_location="{ item }">
            <v-chip
              v-if="item.person_location == null && item.person_has == null"
              color="green"
              dark
            >
              <v-icon>mdi-checkbox-multiple-marked-circle</v-icon>
            </v-chip>
            <v-chip
              v-else-if="item.person_location == null && item.person_has !== ''"
              color="red"
              dark
            >
              <v-icon>mdi-close</v-icon>
            </v-chip>
            <v-chip
              v-else-if="item.person_location !== null"
              color="warning"
              dark
            >
              <span style="font-size:16px;">{{ item.person_location }}</span>
            </v-chip>
            <v-chip v-else color="red" dark>
              <span style="font-size:16px;">{{ item.person_location }}</span>
            </v-chip>
          </template>

          <template v-slot:item.select_item="{ item }">
            <v-checkbox
              v-model="search"
              :value="item.manage_location_id_card"
            ></v-checkbox>
          </template>
          <template v-slot:item.status_position="{ item }">
            <v-chip v-if="item.status_position == null" color="red" dark>
              <v-icon>mdi-close</v-icon>
            </v-chip>
            <v-chip v-else color="green" dark>
              <span style="font-size:16px;">{{ item.status_position }}</span>
            </v-chip>
          </template>
          <template v-slot:item.Mcommittee="{ item }">
            <v-chip
              v-if="item.Mcommittee === 'approp'"
              color="green"
              dark
              :href="'/HRvecfiles/' + item.MdocumentsLinkfile"
              target="_blank"
            >
              <v-icon>mdi-printer</v-icon>
              เห็นควรให้รับย้าย
            </v-chip>
            <v-chip
              v-else-if="item.Mcommittee === 'inapprop'"
              color="warning"
              dark
              :href="'/HRvecfiles/' + item.MdocumentsLinkfile"
              target="_blank"
              ><v-icon>mdi-printer</v-icon>
              ไม่เห็นควรให้รับย้าย
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.Mcommittee"
              color="red"
              large
              @click="cancelDocument(item.manage_location_id_tfl)"
            >
              mdi-delete
            </v-icon>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model canceldialog -->
      <v-layout row justify-center>
        <v-dialog v-model="canceldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ยกเลิกรายการ"
              class="px-5 py-3 text_google"
            >
              <div class="text-right"></div>
            </base-material-card>
            <v-card-text>
              <v-form ref="cancelform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <h3>
                        รหัสอ้างอิง :
                        {{ manage_location_id_tfls.manage_location_id_ref }}
                      </h3>

                      <div class="text--center">
                        รหัสบัตรประชาชน :
                        {{ manage_location_id_tfls.id_card }}
                        <h3>
                          ชื่อ-นามสกุล :
                          {{ manage_location_id_tfls.title_s
                          }}{{ manage_location_id_tfls.frist_name }}
                          {{ manage_location_id_tfls.last_name }}
                        </h3>
                        สถานศึกษาปลายทางที่พิจารณา :
                        {{ manage_location_id_tfls.college_name }}
                      </div>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === 'Th07111988@1']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="canceldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ปิด
              </v-btn>
              <v-btn large color="red" @click.stop="cancelSubmit()" rounded>
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import transferenceManagebar from "../../components/admin/transferenceManagebar.vue";
export default {
  components: { transferenceManagebar },
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",

      valid: true,
      times_select: "",
      years_select: "",
      name_position_s: [
        { text: "สายการสอนและสนับสนุนการสอน", value: "ครู" },
        { text: "สายงานบริหารสถานศึกษา", value: "บริหาร" }
      ],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_time_ss: [1, 2],
      manage_year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_manage_locations: [],

      search: "",
      search_id_card: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      position: "director",
      headersMap: [
        { text: "เลือก", align: "center", value: "select_item" },
        { text: "รหัสสถานศึกษา", align: "center", value: "college_code" },
        { text: "กรณี", align: "center", value: "manage_case_move" },

        {
          text: "สถานศึกษาที่ขอย้ายไป",
          align: "center",
          value: "college_name"
        },
        {
          text: "ลำดับที่ขอย้าย",
          align: "center",
          value: "manage_location_sequence_n"
        },
        { text: "อายุงาน", align: "center", value: "manage_age_app_time" },
        { text: "รหัสบัตร", align: "center", value: "manage_location_id_card" },
        { text: "ผู้ขอย้าย", align: "center", value: "manage_name" },
        {
          text: "ปัจจุบันดำรงตำแหน่ง",
          align: "center",
          value: "position_name"
        },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "สังกัด", align: "center", value: "college_name_now" },
        /*    { text: "เหตุผล", align: "center", value: "manage_reason_detail" }, */

        {
          text: "ความคิดเห็น ผู้บังคับบัญชา.",
          align: "center",
          value: "manage_comment_supervisor"
        },

        { text: "แห่งใหม่ ส.", align: "center", value: "succ_college" },
        { text: "ผลการพิจารณา ว.", align: "center", value: "Mcommittee" },

        {
          text: "วิทยาลัยแห่งใหม่",
          align: "center",
          value: "college_name_suss"
        },
        { text: "เลขที่", align: "center", value: "id_position_new" },
        { text: "ยกเลิกเอกสารแนบ", align: "center", value: "actions" }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      periods: [],
      period_enable_process: "1",
      canceldialog: false,
      manage_location_id_tfls: [],
      data_syslog: {}
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.periodQuery();
    await this.transference_manage_locationQueryAll();
  },

  methods: {
    async cancelDocument(manage_location_id_tfl) {
      let result = await this.$http
        .post("transference_manage_location.php", {
          ApiKey: this.ApiKey,
          manage_location_id_tfl: manage_location_id_tfl
        })
        .finally(() => (this.loading = false));
      this.manage_location_id_tfls = result.data;
      this.canceldialog = true;
    },

    async cancelSubmit() {
      if (this.$refs.cancelform.validate()) {
        this.manage_location_id_tfls.ApiKey = this.ApiKey;
        this.manage_location_id_tfls.Mcommittee = "";
        this.manage_location_id_tfls.MdocumentsLinkfile = "";
        let result;
        result = await this.$http.post(
          "transference_manage_location.admincancel.update.php",
          this.manage_location_id_tfls
        );
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" + this.manage_location_id_tfls.MdocumentsLinkfile
        });

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "cancelDocument";
          this.data_syslog.page_log = "transference_manage_location";
          this.data_syslog.table_log = "transference_manage_location";
          this.data_syslog.detail_log = this.manage_location_id_tfls.manage_location_id_ref;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.transference_manage_locationQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.canceldialog = false;
      }
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_type: "manage",
        periord_admin: "check"
      });
      this.periods = result_period.data;
    },

    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage_location.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.periods.period_times,
          manage_year_s: this.period_years
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;
    },

    async positionSearch() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage_location.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.periods.period_times,
          manage_year_s: this.period_years,
          manage_location_position: this.position
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;
    },

    async transference_manage_locationQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage_location.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.periods.period_times,
          manage_year_s: this.period_years
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;

      
      
    },

    getColor() {
      return "green";
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "grey darken-1";
    },
    period_years() {
      let yyyy = this.periods.period_year;
      return yyyy;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>

<style scoped>
.bgtdNow {
  background-color: rgb(209, 207, 207);
}
.bgtdNew {
  background-color: rgb(125, 229, 247);
}
</style>
