<template>
    <div>
        <v-bottom-navigation color="info" horizontal dark>
          <v-btn to="/admin/timelines/transfer_directors">
        <span>กำหนดการ </span>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>

      <v-btn to="/admin/transference_manage_main">
        <span>ผู้เสนอย้ายสายบริหาร </span>
        <v-icon>mdi-details</v-icon>
      </v-btn>

      <v-btn to="/admin/transference_manage_location_detail">
        <span>รายละเอียดผู้ยืนย้าย ประกอบพิจารณา </span>
        <v-icon>mdi-details</v-icon>
      </v-btn>
      <v-btn to="/admin/transference_manage_location">
        <span>รายละเอียดผู้ยืนย้าย </span>
        <v-icon>mdi-details</v-icon>
      </v-btn>

      <v-btn to="/admin/transference_manage">
        <span>ผลการพิจารณา กลั่นกรอง </span>
        <v-icon>mdi-calculator</v-icon>
      </v-btn> 

      <v-btn to="/admin/transference_manage_report">
        <span>บทสรุป </span>
        <v-icon>mdi-calculator</v-icon>
      </v-btn> 
    </v-bottom-navigation>
    </div>
</template>

<script>
export default {
    name: 'HRvecTransferenceManagebar',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>